<template>
  <div class="mevcut-container min-h-screen my-10">
    <MaterialsCatogriesWithSupportFilter
      :support-filter="true"
      :categories="categories"
    />

    <MaterialsGrid
      class="mb-5 mt-2"
      with-query-params
    />
  </div>
</template>

<script setup lang="ts">
import getAllRoutes from '~/composables/AppApiRoutes'
import type { Category } from '~~/composables/useMenuModel'

const categories = ref<Category[] | undefined>(undefined)
const route = useRoute()
const { t } = useI18n()

await fetchCategories()

async function fetchCategories() {
  if (!route.query.store_type_id) {
    categories.value = undefined
    return
  }

  const { data } = await useBasicFetch(getAllRoutes().categoriesRoutes.categoriesByServer, {
    query: {
      limit: 100,
      store_type_id: route.query.store_type_id,
      with_children: true,
      children_count: true
    },

    transform: (data: SuccessResponse<Category[]>) => {
      const { categoryModel } = useMenuModel()

      return data.data.map(categoryModel)
    }
  })

  categories.value = data.value || undefined
}

const { url } = useDomainHost()

const categoryMeta = computed(() => {
  if (route.query.category_id) {
    const category = categories.value?.find(
      // @ts-ignore
      // eslint-disable-next-line eqeqeq
      (el) => el.id == route.query.category_id
    )
    if (category) {
      return {
        title: category.name,
        image: category.image
      }
    }
  }

  return {
    title: t('materials'),
    image: url + '/images/mevcut.webp'
  }
})

watch(
  () => route.query,
  () => fetchCategories()
)

useServerSeoMeta(
  useOgMeta(
    url + '/materials',
    categoryMeta.value.title,
    t('mevcut_description'),
    categoryMeta.value.image
  )
)

useHead({
  title: () => categoryMeta.value.title,
  link: [useSeoCanonical(url + '/materials')],
  script: [
    useSeoBreadcrumb([
      useSeoBreadcrumbItem(1, t('home'), url),
      useSeoBreadcrumbItem(2, categoryMeta.value.title, url + '/materials')
    ])
  ]
})
</script>
